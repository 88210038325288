import React from "react"
import Layout from "../components/layout"
import { Button, Container } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"


const Success = () => {
  return (
    <Layout center>
      <Container className="py-5">
        <div className="text-center d-flex flex-column align-items-center py-5">
          <h1 className="">Thank You</h1>
          <p>
            We have received you message and we will get in touch as soon as
            possible.
          </p>
          <Link to="/" className="mt-3">
            <Button variant="primary">Go Back</Button>
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export default Success
